import hero from '../assets/hero.jpg'

import Infos from "../components/section/Info";
import { Slider } from '../components';

export const Home = () => {
  // className='bg-green-300 dark:bg-blue-400'
  return (
    <main  className="bg-white dark:bg-gray-900 mt-1">

      <Slider />

      <section >
        <div className="gap-8 items-center py-8 px-4 mx-auto max-w-screen-xl xl:gap-16 md:grid md:grid-cols-2 sm:py-16 lg:px-6">
          <img
            className="w-full dark:hidden"
            src={hero}
            alt="dashboard image"
          />
          <img
            className="w-full hidden dark:block"
            src={hero}
            alt="dashboard image"
          />
          <div className="mt-4 md:mt-0">
            <h2 className="mb-4 text-3xl tracking-tight font-extrabold text-gray-900 dark:text-white">
              Herzlich Willkommen beim Hafia-Bonn
            </h2>
            <p className="mb-6 font-light text-gray-500 md:text-lg dark:text-gray-400">
              Willkommen bei Hafia-Bonn, dem Ort, an dem Sport und Vielfalt
              aufeinandertreffen, um eine lebendige Gemeinschaft zu schaffen.
              Unser gemeinnütziger Verein mit Sitz in Bonn, Deutschland, wurde
              von einer engagierten Gruppe junger Menschen gegründet, darunter
              Studenten, Flüchtlinge und bereits im Berufsleben etablierte
              Absolventen. Unser Hauptziel ist die Förderung der Integration
              durch Fußball. Entdecken Sie unser leidenschaftliches Team, den FC
              HAFIA-BONN, der unser Engagement im Fußball-Verband-Mittelrhein
              (FVM) repräsentiert, der dem Westdeutschen Fußballverband (WDFV)
              und dem Deutschen Fußball-Bund (DFB) angegliedert ist. Wir nehmen
              stolz an nationalen Amateurwettbewerben teil, stärken unseren
              Teamgeist und unsere Liebe zum Spiel. Begleiten Sie uns auf diesem
              Abenteuer, bei dem der Sport Grenzen überwindet und Menschen aus
              allen Lebensbereichen um eine gemeinsame Leidenschaft vereint. Wir
              glauben an die Kraft des Fußballs, um Brücken zu schlagen und eine
              inklusivere Gesellschaft aufzubauen. Durchstöbern Sie unsere
              Website, um mehr über unsere Aktivitäten, kommende Veranstaltungen
              und Möglichkeiten zur Teilnahme an unserer Mission der Integration
              durch Sport zu erfahren. Herzlich willkommen bei Hafia-Bonn!
            </p>
          </div>
        </div>
      </section>

      <Infos />

    
      
    </main>
  );
};
