import { Route ,Routes } from "react-router-dom";
import { Home ,Contact ,Teams , UberUns ,Vorstand } from "../pages";

import React from 'react'

function Allroutes() {
  return (
   <Routes>
     <Route  path='/' element={ <Home />}/>
     <Route  path='/contact' element={ <Contact />}/>
     <Route  path='/teams' element={ <Teams />}/>
     <Route  path='/vorstand' element={ <Vorstand />}/>
     <Route  path='/uber-uns' element={ <UberUns />}/>
   </Routes>
  )
}

export default Allroutes