import { Carousel } from 'flowbite-react';
import foto1 from '../../assets/slider_images/foto_joueur1.jpeg'
import foto2 from '../../assets/slider_images/foto_joueur2.jpeg'
import foto3 from '../../assets/slider_images/foto_joueur3.jpg'

export const Slider = () => {
  return (
    <div className="h-56 sm:h-64 xl:h-80 2xl:h-96">
    <Carousel slideInterval={5000}>
      <img src={foto1} alt="..." />
      <img src={foto2}alt="..." />
      <img src={foto3} alt="..." />
      <img src="https://images.unsplash.com/photo-1553778263-73a83bab9b0c?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTJ8fGZvb3RiYWxsfGVufDB8fDB8fHww" alt="..." />
      <img src="https://images.unsplash.com/photo-1486286701208-1d58e9338013?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="..." />
    </Carousel>
  </div>
  )
}
