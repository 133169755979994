import Map from "../assets/map.jpg";
import {
  FaFacebookSquare,
  FaInstagramSquare,
  FaEnvelope,
  FaPhone
} from "react-icons/fa";

export const Contact = () => {
  return (
    <main  className="bg-white dark:bg-gray-900 mt-1 ">
      <section >
        <div class="gap-8 items-center py-8 px-4 mx-auto max-w-screen-xl xl:gap-16 md:grid md:grid-cols-2 sm:py-16 lg:px-6">
          <img class="w-full dark:hidden" src={Map} alt="dashboard image" />
          <img
            class="w-full hidden dark:block"
            src={Map}
            alt="dashboard image "
          />
          <div class="mt-0 md:mt-0">
            <h2 class="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white">
              Kontakt
            </h2>

            <ul>
            <li>
                {/* Flex container to align elements horizontally */}
                <div className="flex items-center mb-4">
                  {/* Email */}
                  <a
                   
                    className="flex items-center  "
                  >
                    <FaPhone size="4em" className=" dark:text-neutral-100" />
                    <span className="text-black ml-4  dark:text-neutral-100">01512 4430299</span>
                  </a>
                </div>
              </li>
              <li>
                {/* Flex container to align elements horizontally */}
                <div className="flex items-center mb-4">
                  {/* Email */}
                  <a
                    href="mailto:fc-hafia.bonn@web.de"
                    className="flex items-center  "
                  >
                    <FaEnvelope size="4em" className=" dark:text-neutral-100" />
                    <span className="text-black ml-4  dark:text-neutral-100">fc-hafia.bonn@web.de</span>
                  </a>
                </div>
              </li>
              <li>
              <div className="flex items-center mb-4 text-blue-600">
                  {/* Facebook */}
                  <a
                    href="https://www.facebook.com/profile.php?id=100063458213657" target="_blank" rel="noopener noreferrer"

                    className="flex items-center  mr-4"
                  >
                   <FaFacebookSquare size="4em" className="" />
                  <span className="text-black ml-4  dark:text-neutral-100">Facebook</span>
                  </a>
                </div>
               
              </li>
              <li>
              <div className="flex items-center mb-4 text-red-300">
                  {/* Instagram */}
                  <a
                    href="https://www.instagram.com/hafiabonn/" target="_blank" rel="noopener noreferrer"
                    className="flex items-center text-white-600 mr-4"
                  >
                  <FaInstagramSquare size="4em" className="text-rosa-600" />
                  <span className="text-black ml-4  dark:text-neutral-100">Instagram</span>
                  </a>
                </div>
               
              </li>
            </ul>
          </div>
        </div>
      </section>
    </main>
  );
};
