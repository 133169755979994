import { Header ,Footer } from "./components";
import Allroutes from "./routes/Allroutes";

function App() {
  return (
    <>
      <Header />
      <Allroutes />
      <Footer />
    </>
  );
}

export default App;
