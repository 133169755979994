import React from 'react'
import { useEffect } from 'react'
function Fupa() {

    useEffect(() => {
        // Code to embed your external widget
        const script = document.createElement('script');
        script.src = 'https://widget-api.fupa.net/vendor/widget.js?v1';
        script.async = true;
        document.body.appendChild(script);
    
        // Cleanup function (optional)
        return () => {
          document.body.removeChild(script);
        };
      }, []);


  return (
    <div  id="fp-widget_root-2aVaSoe5lBuCB1yO8TQHagFzrXV" ><a href="https://www.fupa.net/club/hafia-bonn" target="_blank" rel="noopener">Hafia Bonn auf FuPa</a></div>

  )
}

export default Fupa

