export const UberUns = () => {
  return (
    <main className="bg-white dark:bg-gray-900 mt-1 ">
      <section >
        <div className="gap-8 items-center py-8 px-4 mx-auto max-w-screen-xl xl:gap-16 md:grid md:grid-cols-2 sm:py-16 lg:px-6">
        
          <div className="mt-4 md:mt-0">
            <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white">
            Über Hafia Bonn
            </h2>
            <p className="mb-6 font-light text-gray-500 md:text-lg dark:text-gray-400">
            Hafia-Bonn ist ein gemeinnütziger Verein mit Sitz in Bonn,
          Deutschland. Er wurde von einer vielfältigen Gruppe junger Menschen
          gegründet, darunter Studenten, Flüchtlinge und Absolventen, die
          bereits in der Arbeitswelt tätig sind. Unser Ziel ist die Förderung
          der Integration durch Fußball.
            </p>
            
            <p className="mb-6 font-light text-gray-500 md:text-lg dark:text-gray-400">
            Als Antwort auf dieses Ziel haben wir den FC HAFIA-BONN ins Leben
          gerufen, eine Fußballmannschaft, die dem Fußball-Verband-Mittelrhein
          (FVM), dem Westdeutschen Fußballverband (WDFV) und dem Deutschen
          Fußball-Bund (DFB) angehört. Durch diese Mitgliedschaften haben wir
          die Möglichkeit, an nationalen Amateurmeisterschaften teilzunehmen.
            </p>

            <p className="mb-6 font-light text-gray-500 md:text-lg dark:text-gray-400">
            Unser Team besteht aus leidenschaftlichen Fußballspielern, die sich
          der Idee der Integration und Gemeinschaft verschrieben haben. Wir
          glauben, dass Fußball eine Brücke bauen kann, die Menschen
          unterschiedlicher Hintergründe zusammenführt.
            </p>
           
          </div>
          
            <div >
            <p className="mb-6 font-light text-blue-500 md:text-lg dark:text-gray-400">
            Wir freuen uns über jegliche Spende zur Finanzierung unseres Vorhaben.
              </p>
           
          <h2 className="mb-4 text-4xl tracking-tight font-extrabold dark:text-white">
          Spendenkonto
            </h2>
            <table className="min-w-full  border border-gray-300 shadow-md text-slate-950 dark:text-gray-500  rounded-md">
   
                <tbody>
                 <tr>
                    <td ><b>Bank</b></td>
                    <td>Sparkasse</td>
                    
                  </tr>
                  <tr>
                    <td><b> Empfanger</b></td>
                    <td>FC HAFIA BONN E.V</td>
                   
                  </tr>
                  <tr>
                    <td><b>IBAN</b></td>
                    <td>DE57 3705 0198 1935 9271 35</td>
                    
                  </tr>
                  <tr>
                    <td><b>BIC</b></td>
                    <td>COLSDE3XXX</td>
                    
                  </tr>
                </tbody>
              </table>
        </div>
        </div>
      </section>
    </main>
  );
};

