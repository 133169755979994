import alpha from '../assets/vorstand/alpha.png'
import malick from '../assets/vorstand/malick.png'
import oumar from '../assets/vorstand/oumar.png'



export const Vorstand = () => {
  return (
  <main  className="bg-white dark:bg-gray-900 mt-1 ">
      <section >
    <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6 ">
        <div className="mx-auto max-w-screen-sm text-center mb-8 lg:mb-16">
            <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white">Vorstand</h2>
                   </div> 
        <div className="grid gap-8 mb-6 lg:mb-16 md:grid-cols-2">
            <div className="items-center bg-gray-50 rounded-lg shadow sm:flex dark:bg-gray-800 dark:border-gray-700">
                <a href="#">
                    <img className="w-full rounded-lg sm:rounded-none sm:rounded-l-lg" src={alpha} alt="Bonnie Avatar" />
                </a>
                <div className="p-5">
                    <h3 className="text-xl font-bold tracking-tight text-gray-900 dark:text-white">
                        Diallo Mamadou Alpha
                    </h3>
                    <span className="text-gray-500 dark:text-gray-400">Präsident</span>
                    <p className="mt-3 mb-4 font-light text-gray-500 dark:text-gray-400"></p>
                   
                </div>
            </div> 
            <div className="items-center bg-gray-50 rounded-lg shadow sm:flex dark:bg-gray-800 dark:border-gray-700">
                <a href="#">
                    <img className="w-full rounded-lg sm:rounded-none sm:rounded-l-lg" src={malick} alt="Malick" />
                </a>
                <div className="p-5">
                    <h3 className="text-xl font-bold tracking-tight text-gray-900 dark:text-white">
                        Malick Diallo
                    </h3>
                    <span className="text-gray-500 dark:text-gray-400">VizePräsident</span>
                   
                </div>
            </div> 
            <div className="items-center bg-gray-50 rounded-lg shadow sm:flex dark:bg-gray-800 dark:border-gray-700">
                <a href="#">
                    <img className="w-full rounded-lg sm:rounded-none sm:rounded-l-lg" src={oumar} alt="Michael Avatar" />
                </a>
                <div className="p-5">
                    <h3 className="text-xl font-bold tracking-tight text-gray-900 dark:text-white">
                    Bah Oumar
                    </h3>
                    <span className="text-gray-500 dark:text-gray-400">Schatzmeister</span>
                  
                </div>
            </div> 
          
        </div>  
    </div>
  </section>
  </main>
  )
}
