import Fupa from "../components/widget/Fupa"


export const Teams = () => {

  
  return (
    <main  className="bg-white dark:bg-gray-900 mt-1 " >
     
      <div className="container mx-auto mt-8  bg-white dark:text-white  dark:bg-gray-900">
      <h1 className="text-2xl font-bold mb-4">Training Plan</h1>
      <table className="min-w-full border border-gray-300 shadow-md rounded-md">
       
          <tr className="bg-gray-200 dark:bg-slate-800">
            <td className="py-2 px-4 border-b">Day</td>
            <td className="py-2 px-4 border-b">Time</td>
          </tr>
      
      
          <tr>
            <td className="py-2 px-4 border-b">Wednesday</td>
            <td className="py-2 px-4 border-b">19:30 - 21:00</td>
          </tr>
          <tr>
            <td className="py-2 px-4 border-b">Friday</td>
            <td className="py-2 px-4 border-b">19:30 - 21:00</td>
          </tr>
          {/* Add more rows for additional days if needed */}
       
      </table>
    </div>
      <Fupa />
    </main>
  )
}
